import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { translations } from './translations';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg="blackAlpha.100"
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      target="_blank"
      _hover={{
        bg: 'blackAlpha.200',
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const router = useRouter();
  const t = translations(router.locale);

  return (
    <Box bg="gray.50" color="gray.700" fontFamily="Poppins">
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '3fr 1fr' }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <img
                alt="Adstart logo"
                src="/logo3.svg"
                height="32px"
                width="200px"
              />
            </Box>
            <Box>
              <Text fontSize={'sm'}>{t.copyright}</Text>
              <Text fontSize={'sm'}>CNPJ: 39.429.495/0001-04</Text>
            </Box>
            <Stack direction={'row'} spacing={6}>
              <SocialButton
                label={'Instagram'}
                href={'https://instagram.com/adstart.com.br'}
              >
                <FaInstagram />
              </SocialButton>
              <SocialButton
                label={'Linkedin'}
                href={'https://www.linkedin.com/company/adstartbr/'}
              >
                <FaLinkedin />
              </SocialButton>
            </Stack>
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>{t.general}</ListHeader>

            <Link href={'/login'}>{t.login}</Link>

            <Link href="https://calendly.com/pedrohjleite">{t.talkToUs}</Link>

            <Link href="/terms">{t.terms}</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
